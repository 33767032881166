.rootElement {
    margin-top: auto;
    margin-right: 5px;
}

@media only screen
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
      .rootElement {
        margin-top: 0;
      }
}