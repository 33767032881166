.appLogoWrapper {
  cursor: pointer;
}

.appLogo {
  vertical-align: sub;
  margin-right: 5px;
}

.settingDialog {
  background-color: transparent;
}

.settingDialogBody {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;
  height: auto;
}

.settingItem {
  width: 100%;
  height: auto;
  min-height: 40px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}


.albumList {
  margin-left: 15px;
  height: auto;
  overflow: auto;
}

.albumListItem {
  cursor: pointer;
}

.settingButton, .fullscreenButton {
  cursor: pointer;
  padding: 0 5px;
}

.userAvatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.userValid {
  border: 1px solid #4CAF50;
}

.userInvalid {
  border: 1px solid #F44336;
}

.loginFab {
  background-color: transparent;
}
.loginFabSetting {
  display: block;
  min-height: 75px ;
  padding: 5px 20px;
}
.loggedUserSetting {
  display: block;
  min-height: 100px;
  padding: 10px 20px;
}
.UserSettingContainer {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  height: auto;
}
.loginFabSettingContainer {
  display: flex;
}
.loginFabSettingContainerLabel {
  margin-top: auto;
  margin-bottom: auto;
}
.UserSettingContainerLabel {
  margin-top: auto;
  margin-bottom: auto;
}

@media only screen
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
      .appTitle {
        padding-left: 0px;
      }
      .appName {
        display: none;
      }
      .titleSeparator {
        display: none;
      }
      .settingButton, .fullscreenButton {
        padding: 5px;
      }
      .loginFab {
        display: none;
      }
      .loginFabSettingContainerLabel {
        font-size: 10px;
      }
}
